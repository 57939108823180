// import { Uploader } from './Uploader.js'

class App {

  /**
   * Options object
   * @type {Object}
   */
  _options = {};

  /**
   * Constructor
   * @param  {Object} options Options object
   * @return {null}
   */
  constructor(options = {}) {
    this._options = options;
    //
    jQuery(document).ready( ($) => {
      this.onDomReady();
    });
  }

  /**
   * On DOM ready callback
   * @return {null}
   */
  onDomReady() {

    $('.js-open-menu').on('click', function(e) {
      e.preventDefault();
      $('.main-menu-drawer').addClass('is-open');
    });

    $('.js-close-menu').on('click', function(e) {
      e.preventDefault();
      $('.main-menu-drawer').removeClass('is-open');
    });

    $('.js-prevent-default').on('click', function(e) {
      e.preventDefault();
    });

    $('.js-bulk').on('change', function() {
      var el = $(this),
        table = el.closest('table'),
        checks = table.find('input[type=checkbox][name="bulk[]"]');
      checks.prop('checked', el.prop('checked'));
    });

    $('.js-circle-progress').each(function() {
      var el = $(this),
        fill = el.data('color') || '#FF0000',
        value = el.data('count') || 0;
      el.circleProgress({
        value: value,
        size: 320,
        fill: fill
      });
    });

    $('.js-chart').each(function() {
      var el = $(this),
        canvas = document.getElementById( el.data('canvas') ),
        config = $.parseJSON( el.val() ),
        chart = new Chart(canvas.getContext('2d'), config);
      el.data('chart', chart);
    });

    $('.js-check-all').on('change', function() {
      var el = $(this),
        checked = el.prop('checked'),
        other = el.closest('.form-group').find('input[type=checkbox]').not(el);
      other.prop('checked', checked);
    });

    $('[data-slug]').on('blur', (e) => {
      let el = $(e.currentTarget),
        target = $( el.data('slug') ),
        val = el.val();
      if ( target.length ) {
        target.val( this.slugify(val) );
      }
    });

    $.datePicker.defaults.dateFormat = function(date) {
      return $.datePicker.defaults.pad(date.getDate(), 2) + '/' + $.datePicker.defaults.pad(date.getMonth() + 1, 2) + '/' + date.getFullYear();
    };

    $.datePicker.defaults.dateParse = function(string) {
      let date = new Date();
      if (string instanceof Date) {
        date = new Date(string);
      } else {
        let parts = string.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
        if ( parts && parts.length == 4 ) {
          date = new Date( parts[3], parts[2] - 1, parts[1] );
        }
      }
      return date;
    };

    $('.js-datepicker').each(function() {
      const input = $(this);
      input.attr('autocomplete', 'off');
      input.on('click', function() {
        let val = input.val();
        let date = val ? $.datePicker.defaults.dateParse(val) : null;
        let widget = $.datePicker.api.show({
          restrictDates: input.data('restrict') == 'none' ? '' : 'future',
          views: {
            month: {
              show: val ? date : '',
              selected: val ? [ date ] : []
            }
          },
          element: input
        });
        input.data('widget', widget);
      });
    });
  }

  /**
   * Slugify string
   * @param  {string} str The string to slugify
   * @return {string}
   */
  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // Trim
    str = str.toLowerCase();
    // Remove accents, swap ñ for n, etc
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '') // Remove invalid chars
      .replace(/\s+/g, '-') // Collapse whitespace and replace by -
      .replace(/-+/g, '-'); // Collapse dashes
    return str;
  }
}

export { App };